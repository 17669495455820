@font-face {
  font-family: 'iconpack';
  src:  url('../fonts/iconpack.eot?cfd1mx');
  src:  url('../fonts/iconpack.eot?cfd1mx#iefix') format('embedded-opentype'),
    url('../fonts/iconpack.ttf?cfd1mx') format('truetype'),
    url('../fonts/iconpack.woff?cfd1mx') format('woff'),
    url('../fonts/iconpack.svg?cfd1mx#iconpack') format('svg');
  font-weight: normal;
  font-style: normal;
}
i.icon{
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'iconpack' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &.icon-envelope:before {
      content: "\e903";
    }
    &.icon-location:before {
      content: "\e905";
    }
    &.icon-mouse:before {
      content: "\e906";
    }
    &.icon-phone2:before {
      content: "\e907";
    }
    &.icon-close:before {
      content: "\e908";
    }
    &.icon-menu:before {
      content: "\e909";
    }
    &.icon-instagram:before {
      content: "\e902";
    }
    &.icon-facebook:before {
      content: "\e904";
    }
    &.icon-phone:before {
      content: "\e942";
    }
}
