@import "_bootstrap-sass-grid";
@import "./libs/fonts";
@import "./libs/icons";
@import "./libs/magnificPopup.css";
@import "./libs/owl.carousel.min.css";

html,body{
    overflow-x: hidden;
}
body{
	background-color: #201862;
	color: #000000;
    line-height: 20px;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 100;
    min-height: 100vh;
    /* START basic options */
    .img-responsive{
        width: 100%;
    }
    .text-center{
        text-align: center;
    }
    .text-right{
        text-align: right;
    }
    .bold{
        font-weight: bold;
    }
    .links{
        a{
            text-decoration: none;
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    .btn-group{
        a{
            display: inline-block;
            margin-bottom: 5px;
        }
    }
    .breadCrumps{
        margin: 0px;
        list-style: none;
        margin-bottom: 10px;
        li{
            display: inline-block;
            color: #201862;
            a{
                color: #201862;
                text-decoration: none;
                border-bottom: 1px solid #201862;
            }
            &::after{
                content: "»";
                padding-left: 5px;
                padding-right: 5px;
                color: #e51a3b;
            }
            &:last-child::after{
                content: "";
                display: none;
            }
        }
    }

    .btn{
        padding: 20px;
        padding-left: 30px;
        padding-right: 30px;
        margin-left: 5px;
        margin-right: 5px;
        font-weight: bold;
        background-color: #e51a3b;
        color: white;
        border-radius: 8px;
        margin-top: 30px;
        text-transform: uppercase;
        text-decoration: none;
        i{
            margin-left: 10px;
            margin-top: 4px;
        }
    }
    .p-t-30{
        padding-top: 30px !important;
    }
    .p-t-60{
        padding-top: 60px !important;
    }
    .p-b-30{
        padding-bottom: 30px;
    }
    .text-left{
        text-align: left;
        img{
            margin-left: 0px;
            margin-right: auto;
        }
    }

    em{
        font-size: 13px;
        color: rgba(0,0,0,.6);
    }
    .cont-box{
        padding-top: 15px;
        padding-bottom: 15px;
        .contactline{
            font-size: 15px;
            line-height: 18px;
            strong{
                display: inline-block;
                width: 100px;
            }
        }
    }
    .form-group{
        margin-bottom: 8px;
        p{
            display: none;
        }
        input[type="text"],input[type="email"],textarea{
            width: 100%;
            padding: 5px;
            background-color: white;
            border: 1px solid rgba(0,0,0,.3);
            line-height: 30px;
            color: black;
            font-size: 15px;
            color: black;
        }
        textarea{
            height: 80px;
            line-height: 20px;
            color: black;
        }
        &.error{
            input,textarea{
                border: 1px solid red;
                border-radius: 0px !important;
            }
            p{
                display: block;
                margin: 0px;
                ul{
                    -webkit-padding-start: 0px;
                    list-style: none;
                    margin: 0px;
                    color: red;
                }
            }
            p.help-block{
                font-size: 13px;
                padding: 0px;
                margin: 0px;
                line-height: 24px;
                width: 100%;
                display: block;
                padding-top: 4px;
            }
            &.checkbox{
                color: red;
            }
        }
        &.checkbox{
            font-size: 13px;
            input{
                margin-right: 10px;
            }
        }

        button{
            border: none;
            padding: 0;
            line-height: 40px;
            color: #fff;
            text-decoration: none;
            font-weight: 700;
            text-transform: uppercase;
            background: #df0008;
            background: linear-gradient(to bottom, #df0008 0, #bd0007 100%);
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
        }

    }
    h1,h2,h3,h4{
        font-size: 30px;
        color: #e52141;
        line-height: 38px;
    }
    h2{
        font-size: 27px;
        color: rgb(0, 0, 102);
        small{
            display: block;
        }
    }
    h3{
        font-size: 25px;
        color: #e51a3b;
    }
    .alert{
        color: white;
        background-color: #920631;
        font-weight: bold;
        font-size: 16px;
        margin-top: 30px;
        padding: 15px;
    }
    /* START basic options */
    .blank{
        width: 100%;
        height: 40px;
    }
    .textColor{
        color: #201862;
    }
    .whiteBg{
        background-color: white;
    }
    .withBg{
        background-image: url(../images/dataBg.jpg);
        background-repeat: no-repeat;
        background-position: right top;
        @media screen and (max-width:990px) {
            &{
                background: none;
                background-color: white;
            }
        }
    }
    .stastk10{
        text-align: center;
        img{
            margin-top: 150px;
            min-width: 100%;
            margin-left: auto;
            margin-right: auto;
            @media screen and (max-width:990px) {
                &{
                    min-width: auto;
                    width: 300px;
                    margin-top: 35px !important;
                }
            }

        }
    }
    a{
        color: #201862;
        cursor: pointer;
        &:hover{
            text-decoration: none;
        }
    }
    a.btns{
        border:1px solid #ed1651;
        border-radius: 60px;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 20px;
        padding-right: 20px;
        margin-left: 5px;
        margin-right: 5px;
        font-size: 12px;
        white-space: nowrap;
        &.active,&:hover{
            background-color: #ed1651;
            color: white;
        }
    }
    ul{
        -webkit-margin-before: 0px;
        -webkit-margin-after: 0px;
        -webkit-margin-start: 0px;
        -webkit-margin-end: 0px;
        -webkit-padding-start: 0px;
    }
    .whiteBg{
        .row ul{
            margin-left: 10px;
            list-style: none;
            li{

                margin-top: 5px;
                margin-bottom: 5px;
                &::before{
                    content: "";
                    background-color: #e51a3b;
                    border-radius: 50%;
                    display: inline-block;
                    margin-right: 10px;
                    margin-bottom: 2px;
                    height: 10px;
                    width: 10px;
                }
            }
        }
    }

    .noPadding{
        padding: 0px !important;
    }
    .noPaddingLeft{
        padding-left: 0px !important;
    }
    .noPaddingRight{
        padding-right: 0px !important;
    }
    .upper{
        text-transform: uppercase !important;
    }
    #line{
        width: 100%;
        height: 25px;
        background-color: #e51a4b;
        border-top: 12px solid #020250;
    }
    .photo{
        img{
            @extend .img-responsive;
            margin-bottom: 20px;
        }
    }
    .photos{
        img{
            @extend .img-responsive;
            margin-bottom: 20px;
        }
        @media screen and (max-width:764px) {
            div.imgs{
                padding: 15px !important;
            }
            div.noPaddingLeft{
                padding-right: 0px !important;
            }
            div.noPaddingRight{
                padding-left: 0px !important;
            }
        }
        &.acords{
            display: none;
            &.active{
                display: block;
            }
        }
    }
    .link{
        width: 100%;
        background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 63%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 63%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 63%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
        position: absolute;
        padding-top: 150px;
        padding-bottom: 40px;
        margin-top: -230px;
        left: 0px;
    }
    /* START offer compoment */
    .services{
        a{
            width:(100%/8);
            background-color: #e51a3b;
            color: white;
            font-weight: bold;
            text-align: center;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            height: 200px;

            align-items: center;
            justify-content: center;
            text-decoration: none;
            font-size: 18px;
            line-height: 26px;
            -webkit-transition: transform .3s; /* Safari */
            transition: transform .3s;
            &:nth-child(1) {
                background: #ed1c24;
            }
            &:nth-child(2) {
                background: #d71921;
            }
            &:nth-child(3) {
                background: #be151b;
            }
            &:nth-child(4) {
                background: #a90e13;
            }
            &:nth-child(5) {
                background: #00aeef;
            }
            &:nth-child(6) {
                background: #006fa4;
            }
            &:nth-child(7) {
                background: #004679;
            }
            &:nth-child(8) {
                background: #0a0048;
            }
            @media screen and (max-width:1026px) {
                width:(100%/4);
            }
            @media screen and (max-width:504px) {
                width:(100%/2);
            }
            &:hover{
                position: relative;
                transform: scale(1.2);
                z-index: 999;
                -webkit-box-shadow: 0px 11px 16px 0px rgba(0,0,0,0.23);
                -moz-box-shadow: 0px 11px 16px 0px rgba(0,0,0,0.23);
                box-shadow: 0px 11px 16px 0px rgba(0,0,0,0.23);
            }
        }
        &.subPage{
            a{
                width: (100%/6);
                padding-left: 35px;
                padding-right: 35px;
                &:nth-child(1) {
                    background: #ed1c24;
                }
                &:nth-child(2) {
                    background: #d71921;
                }
                &:nth-child(3) {
                    background: #be151b;
                }
                &:nth-child(4) {
                    background: #a90e13;
                }
                &:nth-child(5) {
                    background: #8a0b0f;
                }
                &:nth-child(6) {
                    background: #660b0e;
                }
                &:nth-child(7) {
                    background: #00aeef;
                }
                &:nth-child(8) {
                    background: #006fa4;
                }
                &:nth-child(9) {
                    background: #004679;
                }
                &:nth-child(10) {
                    background: #100070;
                }
                &:nth-child(11) {
                    background: #0d005c;
                }
                &:nth-child(12) {
                    background: #09003e;
                }
                @media screen and (max-width:990px) {
                    width:(100%/4);
                }
                @media screen and (max-width:504px) {
                    width:(100%/2);
                }
            }
        }
    }
    /* END offer compoment */
    /* START slider compoment*/
    #slider{
        .text{
            position: absolute;
            text-align: center;
            padding-top: 100px;
            color: white;
            font-size: 18px;
            @media screen and (max-width:1236px) {
                &{
                    padding-top: 80px;
                }
            }
            @media screen and (max-width:1027px) {
                &{
                    padding-top: 40px;
                }
            }
            @media screen and (max-width:580px) {
                i{
                    display: none;
                }
                h2{
                    font-size: 30px !important;
                    small{
                        font-size: 20px !important;
                    }
                }
            }
            h2{
                font-size: 40px;
                color: #e52141;
                background-image: url(../images/line.jpg);
                background-repeat: no-repeat;
                background-position: bottom center;
                background-size: 200px 3px;
                text-transform: none;
                small{
                    font-size: 29px;
                    color: white;
                    padding-top: 5px;
                    padding-bottom: 25px;
                }
            }
            i{
                font-size: 50px;
            }
        }
    }
    /* END slider compoment*/
    /* START contact compoment */
    .contact{
        font-size: 15px;
        line-height: 28px;
        strong,b{
            color: #920631;
            min-width: 80px;
            display: inline-block;
        }
        a{
            color: black;
            text-decoration: none;
        }
    }
    .contactMap{
        height: 300px;
    }
    /* START contact*/
    /* START head options */
    #head{
        padding-top: 0px;
        padding-bottom: 0px;
        border-bottom: 2px solid white;
        background: #ffffff; /* Old browsers */
        background: -moz-linear-gradient(top, #ffffff 0%, #dedede 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #ffffff 0%,#dedede 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, #ffffff 0%,#dedede 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#dedede',GradientType=0 );
        .mobile-btn{
            display: none;
        }
        .logo{
            &::before{
                content: "";
                display: block;
                width: 280px;
                height: 33px;
                position: absolute;
                z-index: 99;
                background-image: url(../images/logo-before.svg);
                background-repeat: no-repeat;
                background-position: center;
                top: -29px;
            }
            img{
                margin-left: 39px;
                position: relative;
                margin-top: -20px;
                max-width: 200px;
                z-index: 998;
            }
        }
        .nav{
            list-style: none;
            padding-top: 25px;
            li{
                display: inline-block;
                font-size: 14px;
                font-weight: 100;
                padding-left: 30px;
                a{
                    text-decoration: none;
                    color: #000000;
                    padding-bottom: 5px;
                    text-transform: uppercase;
                    &.active{
                        font-weight: bold;
                    }
                    &:hover{
                        border-bottom: 1px solid #000000;
                    }
                }
                &.active{
                    a{
                        font-weight: bold;
                    }
                }
                &:hover{
                    a{
                        border-bottom: 1px solid #000000;
                    }
                }
                @media screen and (max-width:1200px) {
                    &{
                        padding-left: 7px;
                        font-size: 13px;
                    }
                    &:first-child{
                        padding-left: 0px !important;
                    }
                }
                @media screen and (max-width:1188px) {
                    &{
                        padding-left: 5px;
                        font-size: 12px;
                    }
                }
            }
        }
    }
    /* START head options */
    /* START data options */
    #data{
        background-color: white;
    }
    .galery{
        padding-top: 10px;
        padding-bottom: 10px;
        a.photo{
            display: block;
        }
    }
    /* END data options */

    /* START map */
    #map {
        #googleMap {
            height: 500px;
        }
        .contact-box {
            padding: 0;
            color: white;
            background-image: url(../images/pinBg.svg);
            background-repeat: no-repeat;
            background-size: auto 400px;
            background-position: center right;
            h3{
                color: white;
                font-size: 30px;
                background-image: url(../images/line.jpg);
                background-repeat: no-repeat;
                background-position: left bottom;
                background-size: 200px 3px;
                padding-bottom: 15px;
                margin-bottom: 30px;
            }
            p{
                padding-left: 30px;
                a{
                    color: white;
                    text-decoration: none;
                }
                i{
                    position: absolute;
                    margin-left: -30px;
                    color: #e51a3b;
                }
            }
        }
    }
    /* END map*/
    .line{
        background-color: #ed1651;
        height: 4px;
    }
    /* START footer options */
    #footer{
        padding-top: 15px;
        padding-bottom: 25px;
        color:white;
        p{
            margin: 0px;
            font-size: 12px;
            color:white;
        }
        a{
            text-decoration: none;
            color: white !important;
            padding-bottom: 4px;
            color: white;
            font-weight: bold;
            &:hover{
                border-bottom: 1px solid white;
            }
        }
    }
    /* START footer options */
    .modal{
        background-color: rgba(255,255,255,.9);
        position: absolute;
        top: 0px;
        width: 100%;
        min-height: 100vh;
        z-index: 999;
        display: none;
        &.active{
            display: block;
        }
        .modal-body{
            margin-top: 100px;
            color: white;
            background-color: #920631;
            a.close-modal{
                position: absolute;
                right: 0px;
                margin-top: -80px;
                color: white;
                font-size: 30px;
                cursor: pointer;
            }
            .body{
                padding-top: 15px;
                padding-bottom: 15px;
            }
            h4{
                display: block;
                text-align: center !important;
                font-size: 28px;
                color: white;
                a{
                    padding-top: 15px;
                    display: block;
                    background: #fff4de; /* Old browsers */
                    background: -moz-linear-gradient(left, #fff4de 0%, #e4cea3 25%, #fff4de 50%, #e4cea3 75%, #fff4de 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(left, #fff4de 0%,#e4cea3 25%,#fff4de 50%,#e4cea3 75%,#fff4de 100%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to right, #fff4de 0%,#e4cea3 25%,#fff4de 50%,#e4cea3 75%,#fff4de 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff4de', endColorstr='#fff4de',GradientType=1 );
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    text-decoration: none;
                    i{
                        font-size: 25px;
                        margin-right: 10px;
                    }
                    span{
                        padding-bottom: 5px;
                    }
                    &:hover{
                        span{
                            border-bottom: 2px solid #e4cea3;
                        }
                    }
                }
            }
            a.btn{
                background-color: white;
                color: #920631;
            }
            .header{
                .close-modal{
                    display: block;
                    width: 40px;
                    height: 40px;
                    font-size: 22px;
                    line-height: 40px;
                    text-align: center;
                    color: #920631;
                    right: 0px;
                    top: 40px;
                    &:hover{
                        color:black;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:1195px) {
    #head{
        .nav{
            li{
                padding-left: 15px !important;
            }
        }
    }
    .offer{
        background-image: none !important;
    }
    .special::before{
        bottom: -100px !important;
    }
}
@media screen and (max-width:1199px) {
    #map {
        .contact-box {
            margin-top: 0 !important;
            position: relative;
            height: 500px;
        }
    }
}
@media screen and (max-width:992px) {
    .hidden-xs{
        display: none;
    }
    #head{
        padding-bottom: 0px !important;
        .container{
            width: 100%;
            padding: 0px;
            .logo{
                padding-left: 15px;
            }
        }
        .mobile-btn{
            display: block !important;
            position: absolute;
            right:30px;
            color: #920631;
            font-size: 30px;
            margin-top: 18px;
        }
        .nav{
            display: none;
            background-color: #e51a3b;
            padding-top: 0px;
            &.active{
                width: 100%;
                left: 0px;
                display: block !important;
                li{
                    float: none;
                    clear: both;
                    display: block;
                    a{
                        display: block;
                        color: white;
                        font-weight: bold;
                        line-height: 40px;
                        &.active,&:hover{
                            color: white;
                        }
                        &:hover{
                            border-bottom: none;
                        }
                    }
                    &:first-child{
                        padding-left: 15px !important;
                    }
                }
            }
        }
    }
}

.row.display-flex {
    display: flex;
    flex-wrap: wrap;
    &::before{
        content: "";
        display: none;
    }
}
.row.display-flex > [class*='col-'] {
    display: flex;
    flex-direction: column;
    float: none;
}

/*
* And with max cross-browser enabled.
* Nobody should ever write this by hand.
* Use a preprocesser with autoprefixing.
*/
.row.display-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items:center;
}

.row.display-flex > [class*='col-'] {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}
